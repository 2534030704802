import React, { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Box,
  Icon,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";

// Material Dashboard 2 React components
import MDConfirm from "components/MDConfirm";

// Apollo client
import { useLazyQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

// Images & Icons
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";

// GraphQL queries & mutations
import { GET_BRANDS_LIST, CREATE_BRAND, UPDATE_BRAND } from "services/brand-service";

// Custom/Additional Libraries
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import AgGrid from "components/MDGrid";

// Custom Context
import { useNotification } from "context";


function BrandListingPage({ setShowBrandForm, setBrandData, setShowBrandsDetails }) {
  const [selectedStatus, setSelectedStatus] = useState("live");
  const { setNotification } = useNotification();

  const handleTabChange = (_event, newValue) => {
    setSelectedStatus(newValue);
  };

  const [fetchBrandsListByStatus, { data, refetch }] = useLazyQuery(GET_BRANDS_LIST, {
    client: dashboardServiceClient,
    fetchPolicy: 'cache-and-network', // Ignore cache for this query
    onError: (error) => {
      console.error("Error: failed to fetch brand list data");
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Error: failed to fetch brand list data",
      });
    }
  });

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    client: dashboardServiceClient,
    refetchQueries: refetch
  })

  const handleStatusChange = (nodeDataId, nodeCellValue) => {
    updateBrand({
      variables: {
        input: {
          id: nodeDataId,
          brandStatus: nodeCellValue,
        },
      },
      onCompleted: () => {
        refetch();
        window.location.reload(); // TODO: need to provide ref for rerendering the grid
        setNotification({
          color: "success",
          isVisible: true,
          message:"Success: brand status updated successfully",
        });
      },
      onError: (error) => {
        console.error("Error: ", error);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Error: failed to update brand status",
        });
      },
    });
  };

  const handleDelete = (id) => {
    updateBrand({
      variables: {
        input: {
          id: id,
          isDeleted: true,
        },
      },
      onCompleted: () => {
        setNotification({
          color: "success",
          isVisible: true,
          message:"Success: brand deleted successfully",
        })
        refetch();
        window.location.reload(); // TODO: need to provide ref for rerendering the grid
      },
      onError: (error) => {
        console.error("Error in Delete ", error);
        setNotification({
          color: "error",
          isVisible: true,
          message:error?.message || "Error: unable to delete brand",
        })
      },
    });
  };

  const generatePdf = (brandData) => {
    const doc = new jsPDF();
    
    // Add title
    doc.setFontSize(18);
    const titleText = 'Vendor Registration Form';
    const titleX = (doc.internal.pageSize.width) / 2;
    doc.setFont('Montserrat', 'bold').text(titleText, titleX, 20, { align: 'center' }).setFont('Montserrat', 'normal');

    const pageWidth = doc.internal.pageSize.width;
    const lineStartX = 15;
    const lineEndX = pageWidth;
  
    // Generate 10 tables
    let startY = 25; // Initial startY position
    const tables = getTables(brandData);
    for (let i = 0; i < tables.length; i++) {
        startY += 5; // Gap between tables
  
        // Prepare table data with random number of rows
        const tableRows = [];
        const tableData = brandData.brandMetaData?.find(bmd=>bmd.key==tables[i].key)?.value;
        if(tableData==null) continue;
        for (let j = 0; j < tables[i].rows.length; j++) {
            tableRows.push([
                tables[i].rows[j].label,
                tables[i].rows[j].useValue? tables[i].rows[j].valueGetter(tableData[tables[i].rows[j].key]) : tableData[tables[i].rows[j].key]
            ]);
        }
  
        // Add table
        doc.setFontSize(12);
        const tableTitle = tables[i].title;
        const titleX = 15; // Start of the table
        const cellPadding = 4;
        const fontSize = 10;
        doc.setFont('Montserrat', 'bold').text(tableTitle, titleX+cellPadding-1, startY + 5).setFont('Montserrat', 'normal');
        doc.setFontSize(10);
        const table = doc.autoTable({
            startY: startY + 6, // Add additional gap between title and tables
            body: tableRows,
            theme: 'plain', // No borders or background
            columnStyles: {
                0: { cellWidth: 35, cellPadding, fontSize }, // Width for first column
                1: { cellWidth: 'auto', cellPadding, fontSize, halign: 'left', fillColor: [255, 255, 255], textColor: [0, 0, 0]} // Padding and border for second column
            },
            didDrawCell: function (data) {
              if (data.column.index === 1) { // Only apply to the second column
                  const cell = data.cell;
                  const padding = 1; // Padding inside the rectangle
                  const x = cell.x;
                  const y = cell.y + padding*2;
                  const width = cell.width;
                  const height = cell.height - padding * 4;

                  doc.setDrawColor(250, 250, 250);
                  doc.roundedRect(x, y, width, height, 1.5, 1.5,'S'); // Draw rounded rectangle
              }
          }
            // margin: { top:5 }, // Adjust margins
        });

        // Update startY position for the next table
        if(i<tables.length - 1){
          const lineY = doc.lastAutoTable.finalY + 5;
          doc.setDrawColor(240, 240, 240);
          doc.setLineWidth(0.001);
          doc.line(lineStartX, lineY, lineEndX, lineY);
          startY = table.lastAutoTable.finalY+2;
        }
    }
    return doc.output('blob');
  };

  const generateExcel = (brandData) => {
    const worksheetData = [];
    const merges = [];
    const tables = getTables(brandData);
    worksheetData.push(['VENDOR REGISTRATION FORM', '']);
    merges.push({ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } });
    worksheetData.push(['','']);
    merges.push({ s: { r: 1, c: 0 }, e: { r: 1, c: 1 } });
    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      const startRow = worksheetData.length;

      // Add table title
      worksheetData.push([table.title, '']);
      merges.push({ s: { r: startRow, c: 0 }, e: { r: startRow, c: 1 } });

      const tableData = brandData.brandMetaData?.find(bmd => bmd.key === table.key)?.value;
      if (!tableData) continue;

      for (let j = 0; j < table.rows.length; j++) {
        worksheetData.push([
          table.rows[j].label,
          table.rows[j].useValue ? table.rows[j].valueGetter(tableData[table.rows[j].key]) : tableData[table.rows[j].key]
        ]);
      }

      // Add an empty row after each table for spacing
      worksheetData.push([]);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply merges
    if (!worksheet['!merges']) {
      worksheet['!merges'] = [];
    }
    worksheet['!merges'] = worksheet['!merges'].concat(merges);

    // Apply styles
    for (const merge of merges) {
      const cell = worksheet[XLSX.utils.encode_cell({ r: merge.s.r, c: merge.s.c })];
      if (cell) {
        cell.s = {
          font: { bold: true },
          alignment: { horizontal: "center" }
        };
      }

      // Apply style to the second cell in the merged range as well
      const secondCell = worksheet[XLSX.utils.encode_cell({ r: merge.s.r, c: merge.s.c + 1 })];
      if (secondCell) {
        secondCell.s = {
          font: { bold: true },
          alignment: { horizontal: "center" }
        };
      }
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Brand Details');

    // Write the workbook to file
    return XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  }

  const extractFileType = (url) => {
    // Regular expression to match the file extension
    const fileTypeRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    
    // Apply the regex to the URL
    const match = url.match(fileTypeRegex);
    
    // Extract and return the file type
    if (match && match.length > 1) {
        return match[1];
    } else {
        return null; // Return null if no match is found
    }
  };

  const generateZip = async (brandData, pdfBlob, excelBlob) => {
    const zip = new JSZip();
    // Add PDF to zip
    zip.file(`${brandData.name}.pdf`, pdfBlob);
    zip.file(`${brandData.name}.xlsx`, excelBlob);

    const artifacts = [
      {name:'Pan card',urlKey:'panCardUrl',metadataKey:'CompanyDetails'},
      {name:'GST',urlKey:'gstUrl',metadataKey:'CompanyDetails'},
      {name:'MSME Certificate',urlKey:'msmeCertificateUrl',metadataKey:'CompanyDetails'},
      {name:'TIN',urlKey:'tinUrl',metadataKey:'CompanyDetails'},
      {name:'Address Proof',urlKey:'addressProofUrl',metadataKey:'CompanyDetails'},
      {name:'Cancelled Cheque',urlKey:'cancelledChequeUrl',metadataKey:'BankDetails'},
      
    ];

    for(let idx in artifacts){
      const url = brandData.brandMetaData?.find(bmd=>bmd.key==artifacts[idx].metadataKey)?.value[artifacts[idx].urlKey];
      if(!url) continue;
      try {
        // Fetch the file
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        // Convert response to ArrayBuffer
        const fileData = await response.arrayBuffer();

        // Determine file extension
        const fileType = extractFileType(url);
        if(fileType==null) continue;
        // Add file to zip
        zip.file(`${artifacts[idx].name}.${fileType}`, fileData,{ type: 'binary' });

    } catch (error) {
        console.error('Error zipping file:', error);
    }

    }
  
    // Add other files to zip
  
    // Generate the zip file and trigger download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `${brandData.name}.zip`);
    });
  };

  const handleDownload = (brandData) => {
    const pdfBlob = generatePdf(brandData);
    const excelBlob = generateExcel(brandData);
    generateZip(brandData, pdfBlob, excelBlob);
  };

  const getTables = (brandData) => [
    {
      title:'COMPANY INFORMATION',
      key:'CompanyDetails',
      rows:[
        {key:'companyName',valueGetter:()=>brandData.name, label:'Company name', useValue:true},
        {key:'address1',label:'Address 1'},
        {key:'address2',label:'Address 2'},
        {key:'city',label:'City'},
        {key:'state',label:'State'},
        {key:'pincode',label:'Pin code'},
        {key:'serviceProvided',label:'Service provided'},
        {key:'companyType',label:'Type of company(Properitor/ Pvt ltd/ public ltd/ Others)', useValue: true, valueGetter: (companyType)=>{
          switch (companyType) {
            case 'llp': return "Limited liability Partnership (LLP)"
            case 'proprietorship': return "Proprietorship";
            case 'partnership': return "Partnership";
            case 'publiclimited': return "Public Limited";
            case 'privatelimited': return "Private Limited";
            default:
              break;
          }
        }},
      ]
    },
    {
      title:'BUSINESS CONTACT',
      key:'ContactDetails',
      rows:[
        {key:'nameSurnameBusiness',label:'Contact person'},
        {key:'phoneNoBusiness',label:'Phone'},
        {key:'mobileNoBusiness',label:'Mobile'},
        {key:'emailBusiness',label:'Email id'},
      ]
    },
    {
      title:'ACCOUNT CONTACT',
      key:'ContactDetails',
      rows:[
        {key:'nameSurnameAccount',label:'Contact person'},
        {key:'phoneNoAccount',label:'Phone'},
        {key:'mobileNoAccount',label:'Mobile'},
        {key:'emailAccount',label:'Email id'},
      ]
    },
    {
      title:'TAX DETAILS',
      key:'CompanyDetails',
      rows:[
        {key:'panCardNo',label:'PAN card no'},
        {key:'gstNo',label:'GST no'},
        {key:'tinNo',label:'TIN no'},
        {key:'tdsSection',label:'TDS Deductible'},
      ]
    },
    {
      title:'BANK DETAILS',
      key:'BankDetails',
      rows:[
        {key:'bankName',label:'Bank name'},
        {key:'bankAccountNo',label:'Bank account no'},
        {key:'bankIFSCCode',label:'Bank IFSC code'},
        {key:'bankAddress',label:'Bank Address'},
        {key:'branch',label:'Branch'},
        {key:'city',label:'Bank City'},
        {key:'state',label:'State'},
        {key:'bankAccountType',label:'Bank Account type (Savings or Current)'},
        {key:'micrNo',label:'MICR No'},
      ]
    }
  ]

  const columns = [
    {
      headerName: "Company Name",
      field: "name",
      width: 250,
    },
    {
      headerName: "Brand Name",
      field: "usp",
      width: 250,
    },
    {
      headerName: "Vendor ID",
      field: "vendorId",
      width: 250,
    },
    {
      headerName: "Customer ID",
      field: "customerId",
      width: 250,
    },
    {
      headerName: "Email ID",
      field: "username",
      width: 250,
    },
    {
      headerName: "Status",
      field: "brandStatus",
      width: 200,
      editable: true,
      cellRenderer: "agGroupCellRenderer", // Custom renderer for group cell (can be used if needed)
      valueGetter: (params) => {
        // You can map your status values here as in your previous code
        if (params?.data?.brandStatus === "Live") {
          return "live";
        } else if (params?.data?.brandStatus === "New") {
          return "new";
        } else if (params?.data?.brandStatus === "Pending") {
          return "pending";
        } else if (params?.data?.brandStatus === "Rejected") {
          return "rejected";
        }
      },
      cellRenderer: (params) => (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={params?.getValue() || params?.data?.brandStatus}
            onChange={(event) =>
              handleStatusChange(params?.node?.data?.id, event?.target?.value) // Update with the row data ID
            }
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem key="live" value="live">
              Live
            </MenuItem>
            <MenuItem key="new" value="new">
              New
            </MenuItem>
            <MenuItem key="pending" value="pending">
              Pending
            </MenuItem>
            <MenuItem key="rejected" value="rejected">
              Rejected
            </MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 180,
      sortable: false,
      filter: false, // Disabling the filter for this column as it's for actions
      cellRenderer: (params) => (
        <Grid item xs={2}>
          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation(); // Stop row click propagation
                setShowBrandForm(true);
                const brandClick =
                  data?.brandlist?.results?.find((brand) => brand.id === params.node.data.id) || {};
                setBrandData(brandClick);
              }}
            >
              <Icon component={previewIcon} fontSize="small" />
            </IconButton>
            
            <IconButton
              aria-label="download"
              onClick={(event) => {
                event.stopPropagation(); // Stop row click propagation
                handleDownload(params.node.data); // Update with row data
              }}
            >
              <Icon component={downloadIcon} fontSize="small" />
            </IconButton>
    
            {/* <MDConfirm
              handleAccept={() => handleDelete(params.node.data.id)} // Update with row data
              title="Delete Brand"
              description={`Are you sure you want to delete "${params.node.data.name}"`}
            >
              {(showDialog) => (
                <IconButton
                  aria-label="delete"
                  onClick={(e) => {
                    e.stopPropagation(); // Stop row click propagation
                    showDialog();
                  }}
                >
                  <Icon component={deleteIcon} fontSize="small" />
                </IconButton>
              )}
            </MDConfirm> */}
          </Box>
        </Grid>
      ),
    }    
  ];

  // TODO: Temp solution to prevent action and details event buble
  let clickTimeout = null; // A reference for the timeout
  const DOUBLE_CLICK_THRESHOLD = 500; // Time in milliseconds (500ms = 0.5 seconds)

  // Function to handle the click
  const handleRowClick = (rowNode) => {
    // If the row was clicked twice quickly (double-click)
    if (clickTimeout) {
      clearTimeout(clickTimeout); // Clear the previous timeout

      // Trigger double-click behavior
      setShowBrandsDetails(true);
      setBrandData(rowNode.data);
      clickTimeout = null; // Reset the timeout
    } else {
      // Single click behavior (set timeout for next click)
      // Delay the action and wait for the next click
      clickTimeout = setTimeout(() => {
        // Trigger single-click behavior (e.g., open the form)
        setBrandData(rowNode.data);
        clickTimeout = null; // Reset the timeout
      }, DOUBLE_CLICK_THRESHOLD);
    }
  };

  return (
    <>
      <Tabs value={selectedStatus} onChange={handleTabChange}>
        <Tab label="Live" value={"live"} />
        <Tab label="New" value={"new"} />
        <Tab label="Pending" value={"pending"} />
        <Tab label="Rejected" value={"rejected"} />
        {/* Add more tabs as needed */}
      </Tabs>
      <AgGrid
        key={`${selectedStatus}`}
        columns={columns}
        readMethod={{
          fetchFn: fetchBrandsListByStatus,
          inputParser: ({ startRow, endRow, filter, sortModel }) => {
            return {
              take: endRow - startRow,
              skip: startRow,
              search: "",
              filter: {
                // ...(filter || {}),
                isDeleted: false,
                brandStatus: selectedStatus,
              },
              searchBy: Object.values(filter) || [],
              searchCols: Object.keys(filter) || [],
              orderby: sortModel.reduce((acc, { colId, sort }) => {
                acc[colId] = sort;
                return acc;
              }, {}),
            };
          },
          resultsParser: ({ data }) => {
            return ({
              total: data?.brandlist?.total || 0,
              results: data?.brandlist?.results || [],
            })
          },
        }}
        gridProps={{
          rowHeight:60,
          defaultColDef:{
            sortable: true,
            editable: false,
            flex: 1,
            minWidth: 200,
            floatingFilter: true,
            filter: 'agTextColumnFilter',
            multiSort: true,
          },
          pivotMode:false,
          sideBar:{
            toolPanels: ['columns', 'filters'], 
          }, 
          enableRangeSelection:true,
          onRowClicked: handleRowClick
        }}  
      />
    </>
  );
}

export default BrandListingPage;
