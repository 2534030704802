import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Drawer,
  Icon,
  CardMedia,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  Chip,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import "./style.css";

// Internal Components and Assets
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import { ReactComponent as searchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as eventDetailsIcon } from "assets/images/eventDetailsIcon.svg";
import { ReactComponent as clockIcon } from "assets/images/clockIcon.svg";
import { ReactComponent as calenderIcon } from "assets/images/calenderIcon.svg";
import { ReactComponent as locationIcon } from "assets/images/locationIcon.svg";
import { ReactComponent as editIcon } from "assets/images/editIcon.svg";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EventsForm from "./brandEventForm";
import { useQuery } from "@apollo/client";
import { GET_EVENTS } from "services/events-service";
import { GET_STORES_LIST } from "services/store-service";
import { eventServiceClient } from "graphql/client";
import { dashboardServiceClient } from "graphql/client";
import dayjs from "dayjs";
import ImageCarousel from "layouts/cateogries/imageCarousel";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";
import EventTabs from "./eventTabs";
import MDButton from "components/MDButton";
import { AuthContext } from "context";



const Events = () => {
  const { user } = useContext(AuthContext);
  const isAdmin = user && ["admin", "broadway-rm"].includes(user.role);

  const [open, setOpen] = useState(false);  // This is used to open and close the drawer
  const [eventData, setEventData] = useState(null); // This is used to set the data of the event
  const [eventList, setEventList] = useState(null); // This is used to set the list of events
  const [currentPage, setCurrentPage] = useState(1); // This is used to set the current page in pagination 
  const [itemsPerPage, setItemsPerPage] = useState(20); // This is used to set the items per page in pagination
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext); // Previously This was used to set the search keyword for global search
  const [selectedEvent, setSelectedEvent] = useState("allEvents"); // this handles the filter of event list based on live, past, upcoming
  const customFilterValue = isAdmin
    ? selectedEvent === "allEvents"
      ? ""
      : selectedEvent
    : "upcoming";

  const totalPages = eventList
    ? Math.ceil(eventList.events.total / itemsPerPage)
    : 0;
  const skip = (currentPage - 1) * itemsPerPage;
  const [tabValue, setTabValue] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_EVENTS, {
    client: eventServiceClient,
    variables: {
      eventListFilter: {
        take: itemsPerPage,
        skip: skip,
        search: searchKeyword,
        // orderby: { eventDate: "DESC" },
        filter: {},
        customfilter: { timing: customFilterValue },
      },
    },
  });
  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST, {
    client: dashboardServiceClient,
    variables: { listStoreFilter: { take: 100, skip: 0 } },
  });
  useEffect(() => {
    if (data && JSON.stringify(data) !== JSON.stringify(eventList)) {
      setEventList(data);
    }
  }, [data]);
  useEffect(() => {
    setCurrentPage(1);
  }, [searchKeyword]);

  // This Function is used to handle the change in items per page
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    const newTotalPages = Math.ceil(eventList.events.total / newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(1); // Reset to first page
    }
  };
  useEffect(() => {
    // Reset search keyword when entering the Brands tab
    setSearchKeyword("");
    setTimeout(() => {
      if (refetch) {
        refetch();
      }
    }, 100);
  }, []);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (refetch) {
      refetch();
    }
  };
// Here we take particuler event as params and set the data to the event data and open the drawer
  const handleCardClick = (item) => {
    setEventData(item);
    setOpen(true);
    setTabValue(0);
  };
// Here The clicked event is duplicated and set the data to the event data and open the drawer emptying name and some other details because same event name cant be duplicated   
  const handleDuplicate = (item ) =>{
    setEventData({...item, id: null,name:"",isLive:false,isPublished:false});
    setOpen(true);
    setTabValue(0);
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography
            sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }}
            ml={1}
          >
            Events
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {user && ["admin", "broadway-rm"].includes(user.role) && (
              <Grid mr={3}>
                <FormControl fullWidth>
                  <Select
                    as={Select}
                    // IconComponent={() => (
                    //   <ArrowDropDownIcon
                    //     style={{ marginRight: "18px", marginTop: "10px" }}
                    //   />
                    // )}
                    value={selectedEvent}
                    onChange={(e) => {
                      setSelectedEvent(e.target.value);
                    }}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value="allEvents">All Events</MenuItem>
                    <MenuItem value="live">Live Events</MenuItem>
                    <MenuItem value="past">Past Events</MenuItem>
                    <MenuItem value="upcoming">Upcoming Events</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
            {user && ["admin", "broadway-rm"].includes(user.role) && (
              <MDButton
                type="button"
                variant="contained"
                color="black"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  fontWeight: 500,
                }}
                circular={true}
                startIcon={<AddIcon />}
                onClick={() => {
                  handleOpen();
                  setEventData(null);
                  setTabValue(0);
                }}
              >
                New Event
              </MDButton>
            )}
          </Box>
        </Grid>
        {!isAdmin
          ? data.events.results
              .filter((event) => event.isPublished === true)
              .map((item, index) => (
                <EventCard
                  key={index}
                  data={item}
                  handleCardClick={handleCardClick}
                  user={user}
                  storeList={storeList}
                />
              ))
          : data.events.results.map((item, index) => (
              <EventCard
                key={index}
                data={item}
                handleCardClick={handleCardClick}
                user={user}
                storeList={storeList}
                handleDuplicate={handleDuplicate}
              />
            ))}
        <EventDrawer
          open={open}
          handleClose={handleClose}
          eventData={eventData}
          tabValue={tabValue}
          setTabValue={setTabValue}
          user={user}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Grid item xs={12} m={1}>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
              transform: "scale(1.2)",
              margin: "20px 0",
            }}
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="secondary"
          />
        </Grid>
        <Grid item xs={2} justifyContent="space-between" display="flex">
          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: 90, marginLeft: 2 }}
          >
            <Typography fontSize={12} fontWeight="bold">
              &nbsp; Page Size
            </Typography>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              sx={{ height: "30px", fontSize: "0.875rem" }}
              inputProps={{ "aria-label": "Without label" }}
              minHeight="20px"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};
function stripHtml(html) {
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}
const EventCard = ({
  data,
  handleCardClick,
  user,
  storeList,
  handleDuplicate,
}) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card
      sx={{ maxWidth: 345, overflowY: "hidden", position: "relative" }}
    >
      
      <CardMedia
        component={() => (
          <>
          {user && ["admin", "broadway-rm"].includes(user.role) && (
              <Chip
                color={data.isLive ? "success" : "error"}
                label={data.isLive ? "Active" : "Inactive"}
                className="customStatusChip"
                style={{ minWidth: "5rem" }}
              />
            )}
            <ImageCarousel
              images={
                data.carouselImages && data.carouselImages.length
                  ? data.carouselImages.map((item) => ({
                      label: item.path,
                      imgPath: item.publicurl,
                    }))
                  : [
                      {
                        label: "fallback image",
                        imgPath:
                          "https://generation-sessions.s3.amazonaws.com/0ba0bd1fa5668ead38ed299462c51bef/img/frame-1171274784@2x.png",
                      },
                    ]
              }
            />
            
          </>
        )}
        sx={{ margin: 0, maxWidth: "100%" }}
        alt="green iguana"
      />
      <CardContent sx={{ paddingTop: "16px", paddingBottom: "0px" }}>
        {user && user?.role === "admin" && (
          <Typography
            gutterBottom
            variant="h7"
            sx={{
              fontSize: "12px",
              color: "#026BE7",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            component="div"
          >
            {data.eventType == "oneToMany"
              ? "One to Many Event"
              : data.eventType == "oneToOne"
              ? "One to One Event"
              : ""}
          </Typography>
        )}
        <Typography
          gutterBottom
          variant="h7"
          sx={{
            fontSize: "13px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="div"
        >
          {data.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="text.secondary"
        >
          {stripHtml(data.description)}
        </Typography>
      </CardContent>
      <Divider />
      <CardContent sx={{ display: "block", justifyContent: "space-between"}}>
        <Grid display="block">
          <Typography
            sx={{ fontSize: "12px" }}
            color="text.secondary"
            size="small"
          >
            <Icon component={locationIcon} /> &nbsp;
            {`${
              storeList?.stores?.results.find(
                (store) => store?.id === data?.storeId
              )?.name
            }, ${
              storeList?.stores?.results.find(
                (store) => store?.id === data?.storeId
              )?.city
            }`}
          </Typography>
          <Typography
            sx={{ fontSize: "12px" }}
            color="text.secondary"
            size="small"
          >
            <Icon component={clockIcon} />{" "}
            {dayjs(data.startTime, "HH:mm").format("hh:mm A")}
          </Typography>
          <Typography
            sx={{ fontSize: "12px" }}
            color="text.secondary"
            size="small"
          >
            <Icon component={calenderIcon} />
            {dayjs(data.startDate).format("MMM DD, YYYY")}
          </Typography>
          <Divider />

          {user && ["admin", "broadway-rm"].includes(user.role) ? (
            <Grid container display="flex" gap={2} justifyContent="space-between" alignItems="center">
              <Grid item >
              <IconButton
                aria-label="like"
                sx={{
                  padding: "0px",
                  marginLeft: "auto",
                  border: "1px solid #B5E4FF",
                  borderRadius: "100px",
                  width: "55px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F3FBFF",
                }}
              >
                <ThumbUpOffAltIcon
                  sx={{ fontSize: "small", color: "#026BE7" }}
                  fontSize="small"
                />
                <Typography
                  variant="body2"
                  sx={{ paddingLeft: 0.5 }}
                  fontWeight="bold"
                  fontSize={12}
                >
                  {data?.sponser?.length}
                </Typography>
              </IconButton>
              </Grid>
              <Grid item >
              <IconButton
                onClick={() => handleCardClick(data)}
                sx={{ fontSize: 16, cursor: "pointer" }}
              >
                <Icon component={editIcon} />
              </IconButton>
              <IconButton onClick={() => handleDuplicate(data)}>
                <ContentCopyIcon fontSize="small" />
              </IconButton>
              </Grid>
            </Grid>
          ) : (
            <MDButton
              type="button"
              variant="outlined"
              color="dark"
              circular={true}
              onClick={() => handleCardClick(data)}
            >
              View Details
            </MDButton>
          )}
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

const EventDrawer = ({
  open,
  handleClose,
  eventData,
  tabValue,
  setTabValue,
  user
  }) => {
    useEffect(() => {
      if (open) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
      return () => {
        document.documentElement.style.overflow = "auto";
      };
    }, [open]);
  return (
  <Drawer
    anchor="right"
    open={open}
    onClose={(event, reason) => {
      if (reason === "backdropClick") return; // Ignore backdrop clicks
      handleClose(); 
    }}
    PaperProps={{ style: { width: "50%" } }}
  >
    <Grid container sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: 1.5 }}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Icon
          sx={{ mr: 2, marginLeft: "10px" }}
          component={eventDetailsIcon}
          fontSize="large"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ marginLeft: "10px" }}>
          Event Details
        </Typography>
      </Grid>
    </Grid>
    <Box sx={{ width: "100%", minHeight: "70vh" }}>
      {user && !["admin", "broadway-rm"].includes(user.role) ? (
        <EventsForm handleClose={handleClose} eventData={eventData} />
      ) : (
        <EventTabs
          eventData={eventData}
          handleClose={handleClose}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      )}
    </Box>
  </Drawer>
);
}

export default Events;
