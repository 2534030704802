import React, { useContext, useEffect, useRef, useState } from 'react'

// Custom Context
import { AuthContext, useNotification } from 'context'

// Custom Components
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from '../../components/MDButton';

// @mui material components
import { Grid, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Slide, Divider, Button, } from '@mui/material';

// Images & Icon
import { Add as AddIcon, Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExportIconPng from '../../assets/images/icons/export.png';

// Custom/Additional Components
import BrandListingPage from './BrandListingPage';
import BrandDetailsTab from './form';
import BrandForm from './newCompanyForm';
import "./style.css";
import { useFormDirtyContext } from 'context';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export const ExportIcon = () => (
  <img src={ExportIconPng} alt="Component Icon" width="20" height="20" />
);

export const DialogBox = ({ title, description, handleSave, status, setStatus, handleDontSave, dirtyData, showDialog }) => {

  const handleOpen = () => {
    setStatus(true);
  };

  const handleClose = () => {
    setStatus(false);
  };
  return (
    <Dialog
      open={status}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <Grid sx={{ paddingX: "25px" }}>
        <DialogTitle
          style={{
            color: "black",
            fontFamily: "Montserrat",
            textAlign: "center"
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#000000",
          }}
        >
          <Close />
        </IconButton>
        <Divider />
        <DialogContent style={{ maxWidth: "350px" }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "black", fontFamily: "Montserrat", textAlign: "center" }}
          >
            {description}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <MDButton
            variant="outlined"
            style={{
              backgroundColor: "white",
              color: "black",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              border: "1px solid black",
              width: "50%",
            }}
            onClick={handleDontSave}
          >
            Don't Save
          </MDButton>
          <MDButton
            variant="contained"
            style={{
              backgroundColor: "black",
              color: "white",
              fontFamily: "Montserrat",
              borderRadius: "50px",
              width: "50%",
            }}
            onClick={() => { handleSave(); handleClose(); }}
          >
            Save
          </MDButton>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

function Brands() {
  const { user } = useContext(AuthContext);
  const { setIsDirty, setHandleFormSave } = useFormDirtyContext();
  const { setNotification } = useNotification();
  const [showBrandsDetails, setShowBrandsDetails] = useState(false);
  const [openNewCompanyForm, setOpenNewCompanyForm] = useState(false);
  const [brandData, setBrandData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [formTabValue, setFormTabValue] = useState(0);
  const [skipDirtyValidation, setSkipDirtyValidation] = useState(false);
  const [tempTabValue, setTempTabValue] = useState(0);
  const [dirtyData, setDirtyData] = useState(false);
  const [backNavigate, setBackNavigate] = useState(false);
  const [key, setKey] = useState(new Date())
  const companyFormSubmitRef = useRef();

  useEffect(() => {
    setIsDirty(dirtyData)
  }, [dirtyData])

  const handleTabChange = (_event, newValue) => {
    setShowDialog(false)
    if (skipDirtyValidation) {
      setShowDialog(false)
      setFormTabValue(newValue)
    }
    else if (dirtyData) {
      setShowDialog(true);
    }
    else {
      setFormTabValue(newValue);
    }
    setSkipDirtyValidation(false)
  };
  const handleDontSave = () => {
    setShowDialog(false);
    setFormTabValue(tempTabValue)
    setDirtyData(false)
    if (backNavigate) {
      setBackNavigate(false);
      setShowBrandsDetails(false);
      setFormTabValue(0)
    }
  }

  useEffect(() => {
    let validate = true;
    const newHandleFormSave = () => {
      switch (formTabValue) {
        case 0:
          if (!Object.keys(companyFormSubmitRef.current.validateFormCompanyDetail()).length) {
            setHandleFormSave(companyFormSubmitRef.current.validateFormCompanyDetail());
            companyFormSubmitRef.current.handleSubmitCompanyDetail();
            setDirtyData(false)
            setShowDialog(false)
            setFormTabValue(0)
          }
          else {
            validate = false;
          }
          break;
        case 1:
          if (!Object.keys(companyFormSubmitRef.current.validateFormCompanyVerification()).length) {
            companyFormSubmitRef.current.handleSubmitCompanyVerification();
            setHandleFormSave(companyFormSubmitRef.current.handleSubmitCompanyVerification());
            setDirtyData(false)
            setShowDialog(false)
            setFormTabValue(tempTabValue)
          }
          else {
            validate = false;
          }
          break;
        case 2:
          if (!Object.keys(companyFormSubmitRef.current.validateFormContactDetail()).length) {
            setHandleFormSave(companyFormSubmitRef.current.validateFormContactDetail());
            companyFormSubmitRef.current.handleSubmitContactDetail();
            setDirtyData(false)
            setShowDialog(false)
            setFormTabValue(tempTabValue)
          }
          else {
            validate = false;
          }
          break;
        case 3:
          if (!Object.keys(companyFormSubmitRef.current.validateFormBankDetail()).length) {
            setHandleFormSave(companyFormSubmitRef.current.validateFormBankDetail());
            companyFormSubmitRef.current.handleSubmitBankDetail();
            setDirtyData(false)
            setShowDialog(false)
            setFormTabValue(tempTabValue)
          }
          else {
            validate = false;
          }
          break;
        default:
          setDirtyData(false)
          setShowDialog(false)
          setFormTabValue(0)
          break;
      }
      return validate
    };

    setHandleFormSave(() => newHandleFormSave);
  }, [formTabValue]);


  const handleSave = () => {
    let validate = true;
    switch (formTabValue) {
      case 0:
        if (!Object.keys(companyFormSubmitRef.current.validateFormCompanyDetail()).length) {
          setHandleFormSave(companyFormSubmitRef.current.validateFormCompanyDetail());
          companyFormSubmitRef.current.handleSubmitCompanyDetail();
          setDirtyData(false)
          setShowDialog(false)
          setFormTabValue(tempTabValue)
        }
        else {
          validate = false;
        }
        break;
      case 1:
        if (!Object.keys(companyFormSubmitRef.current.validateFormCompanyVerification()).length) {
          companyFormSubmitRef.current.handleSubmitCompanyVerification();
          setHandleFormSave(companyFormSubmitRef.current.handleSubmitCompanyVerification());
          setDirtyData(false)
          setShowDialog(false)
          setFormTabValue(tempTabValue)
        }
        else {
          validate = false;
        }
        break;
      case 2:
        if (!Object.keys(companyFormSubmitRef.current.validateFormContactDetail()).length) {
          setHandleFormSave(companyFormSubmitRef.current.validateFormContactDetail());
          companyFormSubmitRef.current.handleSubmitContactDetail();
          setDirtyData(false)
          setShowDialog(false)
          setFormTabValue(tempTabValue)
        }
        else {
          validate = false;
        }
        break;
      case 3:
        if (!Object.keys(companyFormSubmitRef.current.validateFormBankDetail()).length) {
          setHandleFormSave(companyFormSubmitRef.current.validateFormBankDetail());
          companyFormSubmitRef.current.handleSubmitBankDetail();
          setDirtyData(false)
          setShowDialog(false)
          setFormTabValue(tempTabValue)
        }
        else {
          validate = false;
        }
        break;
    }
    if (backNavigate) {
      setBackNavigate(false);
      if (validate) {
        setShowBrandsDetails(false);
        setFormTabValue(0)
      }
    }
  }

  const handleBrandExport = async (_event) => {
    // Brand Export Logic
    const url = process.env.REACT_APP_NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/brands/export`
      : `/dashboard-service/brands/export`;
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        let d = new Date();
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        const hour = String(d.getHours()).padStart(2, '0');
        const minute = String(d.getMinutes()).padStart(2, '0');
        const second = String(d.getSeconds()).padStart(2, '0');
        link.download = `Brand_Report_${day}${month}${year}_${hour}${minute}${second}.xlsx`; // Use epoch timestamp for filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        throw new Error("Failed to download template");
      }
    } catch (error) {
      console.error("Error while exporting brands", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Error while exporting brands",
      })
    }
  }

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              alignItems: "baseline",
            }}
          >
            <MDTypography
              sx={{
                fontFamily: "montserrat",
                color: "#000",
                fontWeight: "700",
              }}
              ml={1}
            >
              Brands
            </MDTypography>
            {user && ["admin", "broadway-rm"].includes(user?.role) && !showBrandsDetails && (
              <Grid item xs={12} sm={6} md={6} textAlign="right">
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <MDButton
                    type="button"
                    variant="outlined"
                    color="black"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                      backgroundColor:"#fff"
                    }}
                    circular={true}
                    startIcon={<ExportIcon />}
                    onClick={handleBrandExport}
                  >
                    Export
                  </MDButton>

                  <MDButton
                    type="button"
                    variant="contained"
                    color="black"
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                    circular={true}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setBrandData(null)
                      setOpenNewCompanyForm(true)}}
                  >
                    New Company
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </MDBox>
        </Grid>
      </Grid>
      {user && ["admin", "broadway-rm"].includes(user.role) ? (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
          >
          {showBrandsDetails ? (
            <>
              <IconButton
                aria-label="Go Back"
                onClick={() => {
                  if (dirtyData) {
                    handleTabChange(null, 0);
                    setBackNavigate(true);
                  }
                  else {
                    setShowBrandsDetails(false)
                    setFormTabValue(0);
                  }
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                >
                  Back
                </Typography>
              </IconButton>
              <BrandDetailsTab setSkipDirtyValidation={setSkipDirtyValidation} brandData={brandData} setShowDialog={setShowDialog} formTabValue={formTabValue} setFormTabValue={setFormTabValue} handleTabChange={handleTabChange} setTempTabValue={setTempTabValue} setDirtyData={setDirtyData} ref={companyFormSubmitRef} />
            </>
          ) : (
            <BrandListingPage
            key={key}
            setShowBrandForm={(status) => setOpenNewCompanyForm(status)}
            setBrandData={(brandData) => setBrandData(brandData)}
              setShowBrandsDetails={setShowBrandsDetails}
            />
          )}
        </MDBox>
      ) : (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <BrandDetailsTab setSkipDirtyValidation={setSkipDirtyValidation} setShowDialog={setShowDialog} setTempTabValue={setTempTabValue} formTabValue={formTabValue} setFormTabValue={setFormTabValue} handleTabChange={handleTabChange} setDirtyData={setDirtyData} ref={companyFormSubmitRef} />
        </MDBox>
      )}
      <DialogBox title={"Alert"} description={"There are unsaved data, do you want to save the changes"}
        status={showDialog}
        setStatus={setShowDialog}
        handleDontSave={handleDontSave}
        handleSave={handleSave}
        showDialog={showDialog}
        dirtyData={dirtyData}
      />

      {openNewCompanyForm && (
        <BrandForm
          brandData={brandData}
          handleClose={() => setOpenNewCompanyForm(false)}
          setKey={setKey}
        />
      )}
    </DashboardLayout>
  );
}

export default Brands