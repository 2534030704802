import React, { useState, useEffect } from 'react';
// MUI imports
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, Grid } from '@mui/material';
//Apollo Client Imports
import { useLazyQuery } from '@apollo/client';
import { GET_STORES_LIST_ADS } from 'services/store-service';
import { dashboardServiceClient } from 'graphql/client';
//Custom Imports
import useDebounce from 'layouts/product-category/useDebounce';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { GET_STORE_ENTRANCE_LIST } from 'services/report-service';

function StoreDropdown({ selectStore, setSelectStore, selectedEntrance, setSelectedEntrance }) {
    const [storeOptions, setStoreOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [entranceOptions, setEntranceOptions] = useState([]); // State for flattened entrances

    const debouncedSearchTerm = useDebounce(inputValue, 300);

    const [
        getStoreList,
        { loading: storeListLoading, data: storelistData, error: storeListError },
    ] = useLazyQuery(GET_STORES_LIST_ADS, {
        client: dashboardServiceClient,
        fetchPolicy: 'network-only',
    });
    const [getEntranceList, { loading: entranceListLoading, data: entrance, error: entranceError }] = useLazyQuery(GET_STORE_ENTRANCE_LIST, {
        client: dashboardServiceClient,
        fetchPolicy: 'network-only',
    })
    useEffect(() => {
        getStoreList({
            variables: {
                listStoreFilter: {
                    take: 10,
                    skip: 0,
                    search: debouncedSearchTerm,
                    filter: {
                        status: 'active',
                    },
                },
            },
        });
    }, [getStoreList, debouncedSearchTerm])

    useEffect(() => {
        if (selectStore) {
            getEntranceList({
                variables: {
                    listStoreFilter: {
                        take: 10,
                        skip: 0,
                        search: debouncedSearchTerm,
                        filter: {
                            status: 'active',
                            id: selectStore,
                            storeMetaData: { key: "entrances" }
                        },
                    },
                }
            })
        }else {
            setEntranceOptions([]); 
        }
    }, [selectStore])
    console.log(selectedEntrance, selectedEntrance.length, "entrance")

    useEffect(() => {
        if (storelistData && storelistData.stores && storelistData.stores.results) {
            setStoreOptions(storelistData.stores.results);
        }
    }, [storelistData]);

    useEffect(() => {
        if (entrance && entrance.stores && entrance.stores.results) {
            const entrances = entrance.stores.results[0]?.storeMetaData[0]?.value?.entrances || [];
            setEntranceOptions(entrances);
        }
    }, [entrance,selectStore]);

    return (
        <Grid container display="contents"  >
            <Grid item xs={12}>
                <Autocomplete
                    options={storeOptions.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))}
                    getOptionLabel={(option) => option.label || ''}
                    loading={storeListLoading}
                    onChange={(event, newValue) => {
                        setSelectStore(newValue?.value);
                        setSelectedEntrance("")
                    }}
                    onInputChange={(event, newValue) => {
                        if ((event && event?.type === "reset") || newValue === "") {
                            setInputValue("");
                        } else if (event && event.type === "change") {
                            setInputValue(newValue);
                        }
                    }}
                    onBlur={() => {
                        setInputValue("");
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: 40,
                            px: 2,
                            backgroundColor: "white.main",
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select a store"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel>Select Entrance</InputLabel>
                    <Select
                        multiple
                        as={Select}
                        disabled={!selectStore}
                          value={selectedEntrance || []}
                        label="Select Entrance"
                        IconComponent={() => (
                            <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                        )}
                        sx={{ height: 40 }}
                        onChange={(e) => {
                            setSelectedEntrance(e.target.value);
                        }}
                        renderValue={(selected) => selected.join(", ")}
                    >
                        {entranceOptions.map((item) => (
                            <MenuItem key={item?.name} value={item?.name}>
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

        </Grid>
    );
}

export default StoreDropdown;
