
import React, { useMemo,useState } from 'react';

// Ag Grid Imports
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
//Day js imports
import dayjs from 'dayjs';
//Mui Imports
import { Modal, Box, Button,IconButton } from '@mui/material';
//Icons
import CloseIcon from '@mui/icons-material/Close';


const FootfallGrid = ({ rowsData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
  
    // Function to open the modal with the selected image
    const openImageModal = (imageUrl) => {
      setModalImageUrl(imageUrl);
      setIsModalOpen(true);
    };
  
    // Function to close the modal
    const closeModal = () => {
      setIsModalOpen(false);
      setModalImageUrl('');
    };
  
  // Flatten the nested data structure into a list of rows Here we are creating the desired data structure for the ag-grid on frontend.
  const rowData = useMemo(() => {
    const rows = [];
    rowsData?.Footfalls?.storeWiseFootfall.forEach(store => {
      store.dateWiseFootfall.forEach(date => {
        date.timeWiseFootfalls.forEach(time => {
          time.entranceWiseFootfall.forEach(entrance => {
            rows.push({
              storeName: `${store.storeName} (${store.totalFootFallForStore})`,
              date: `${dayjs(date.date).format('DD/MM/YYYY')} (${date.totalFootfallForDay})`,
              time: `${dayjs(time.tillTime).format('hh:mm A')} (${time.timeWiseTotal})`,
              entrance: entrance.name,
              fromTime: dayjs(entrance.fromTime).format('hh:mm A'),
              toTime: dayjs(entrance.tillTime).format('hh:mm A'),
              totalVisitors: entrance.count,
              createdBy: entrance.createdBy,
              image: entrance.imageUrl,
            });
          });
        });
      });
    });
    return rows;
  }, [rowsData]);

  // Column definitions
  const columnDefs = [
    { field: 'storeName', rowGroup: true, hide: true },
    { field: 'date', rowGroup: true, hide: true },
    { field: 'time', rowGroup: true, hide: true },
    { field: 'entrance', headerName: 'Entrance', filter:true },
    { field: 'date', headerName: 'Date', cellRenderer: params => {
        if (params.node.group) {
          return params.value;
        } else {
          const dateString = params.value.split(' (')[0];
          return dayjs(Number(dateString)).isValid() ? dayjs(Number(dateString)).format('DD/MM/YYYY HH:mm:ss') : dateString;
        }
      } },
    { field: 'fromTime', headerName: 'From time',filter: true, },
    { field: 'toTime', headerName: 'To time',filter: true, },
    { field: 'totalVisitors', headerName: 'Total Visitors',filter: true, },
    { field: 'createdBy', headerName: 'Created By',filter: true, },
    {
        field: 'image',
        headerName: 'Image',
        cellRenderer: params => params.value ? (
          <span style={{ color: '#007AFF', cursor: 'pointer' }} onClick={() => openImageModal(params.value)}>View Image</span>
        ) : null
      }
  ];

  const defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
  };
  return (
    <>
    <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={{
            headerName: 'Group',
            field: 'storeName',
            cellRenderer: (params) => {
              return groupRowInnerRenderer(params);
            },
          }}
        groupDisplayType={'groupRows'}
        pagination={true}
        paginationPageSize={10}
        groupRowRendererParams={{
            suppressCount: true
        }}
  

      />
    </div>
    <Modal open={isModalOpen} onClose={closeModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxWidth: '700px',
          p: 2,
          overflowY: "auto",
          maxHeight: "90%",
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
            <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color:"#000000",
            }}
          >
            <CloseIcon />
            </IconButton>
          <img src={modalImageUrl} alt="Footfall Image" style={{ width: '100%', height: '100%',padding:"5px" }} />
        </Box>
      </Modal>
    </>
  );
};

export default FootfallGrid;
