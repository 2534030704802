import { useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import Loader from "components/MDLoader";
import { dashboardServiceClient } from "graphql/client";
import React from "react";
import { LIST_INFORMATION_GUIDES } from "services/brand-service";

import InformationGuideCard from "./informationGuideCard";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const MODULES = [
  "Vendor Onboarding",
  "Price Update",
  "Product Onboarding",
  "Reports",
  "Promotion Request",
];

const InformationGuideListings = ({ onItemClick, isAdmin }) => {
  const { loading, error, data, refetch } = useQuery(LIST_INFORMATION_GUIDES, {
    client: dashboardServiceClient,
    variables: {
      listInformationGuideFilter: {
        take: 9999,
        skip: 0,
        search: "",
        filter: {
          isVisibleToBrands: !isAdmin ? true : null,
          status: !isAdmin ? "Published" : null,
          isDeleted: false,
        },
      },
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      //   setRowCount(data?.masterActivity?.total);
    },
  });
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const groupedByModule = MODULES.reduce((acc, module) => {
    acc[module] = data?.informationGuides?.results?.filter(
      (ig) => ig?.module === module ) || []
    return acc;
  }, {});
  console.log(groupedByModule,"grouped >>>");
  return (
    <div>
      {MODULES.map((module) => (
        <Accordion key={module}>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>{module &&  module === "Price Update" ? "Broadway Documents": module }</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {groupedByModule[module].length > 0 ? (
                groupedByModule[module]?.map((informationGuide) => (
                  <InformationGuideCard
                    key={informationGuide.id}
                    isAdmin={isAdmin}
                    onEditClick={onItemClick}
                    informationGuide={informationGuide}
                  />
                ))
              ) : (
                <></>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default InformationGuideListings;
