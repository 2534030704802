import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { dashboardServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import MDButton from "components/MDButton";
import FileUploader from "./informationGuideFileUploader";

import { LIST_INFORMATION_GUIDES } from "services/brand-service";
import { CREATE_INFORMATION_GUIDE } from "services/brand-service";
import { UPDATE_INFORMATION_GUIDE } from "services/brand-service";

const validationSchema = Yup.object().shape({
    informationGuideName: Yup.string()
      .required("Name is required")
      .max(30, "Maximum 30 characters allowed"),
    informationGuideDescription: Yup.string()
      .required("Description is required")
      .max(150, "Maximum 150 characters allowed"),
    isVisibleToBrands: Yup.boolean(),
    module: Yup.string().required("Module is required"),
    fileType: Yup.string().required("File type is required"),
    contentFilePath: Yup.string()
    .required('Content File is required')
    .when('fileType', {
      is: (value)=>value=='Document',
      then:()=> Yup.string()
        .matches(/\.(doc|DOC|docx|DOCX|pdf|PDF|xls|XLS|xlsx|XLSX)$/i, 'Only doc, docx, pdf, xls, xlsx files are allowed'),
    })
    .when('fileType', {
      is: (value)=>value=='Video',
      then: ()=>Yup.string()
        .matches(/\.mp4$/i, 'Only mp4 files are allowed'),
    }),
    contentFilePublicurl: Yup.string().required('Content File is required'),
    status: Yup.string().optional()
});

const useInformationGuideMutations = (informationGuideData, handleClose, setNotification) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: LIST_INFORMATION_GUIDES,
        variables: {
          listInformationGuideFilter: {
            take: 9999,
            skip: 0,
            search: "",
            filter: {
              isDeleted: false,
            },
          },
        },
      },
    ],
  };
  const [createInformationGuideMutation] = useMutation(
    CREATE_INFORMATION_GUIDE,
    mutationOptions
  );

  const [updateInformationGuideMutation] = useMutation(
    UPDATE_INFORMATION_GUIDE,
    mutationOptions
  );
  const handleSubmit = (values) => {
    const payload = {
      name: values.informationGuideName,
      description: values.informationGuideDescription,
      isVisibleToBrands: values.isVisibleToBrands,
      module: values.module,
      contentFileType: values.fileType,
      contentFilePath: values.contentFilePath,
    };
    const mutation =
    informationGuideData && informationGuideData?.id ? updateInformationGuideMutation : createInformationGuideMutation;
    const mutationInput =
      informationGuideData && informationGuideData?.id
        ? {
            updateInformationGuideInput: {
              id: informationGuideData.id,
              status: values.status,
              ...payload,
            },
          }
        : { createInformationGuideInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        handleClose(true);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            informationGuideData && informationGuideData.id
              ? "Information Guide updated successfully"
              : "Information Guide created successfully",
        });
      })
      .catch((error) => {
        console.error("error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };
  return handleSubmit;
};
const InformationGuideForm = ({ handleClose, informationGuideData, isAdmin }) => {

  const { setNotification } = useNotification();
  const handleSubmit = useInformationGuideMutations(
    informationGuideData,
    handleClose,
    setNotification
  );
  
  return (
    <Formik
      initialValues={{
        informationGuideId: informationGuideData?.id || "",
        informationGuideName: informationGuideData?.name || "",
        informationGuideDescription: informationGuideData?.description || "",
        isVisibleToBrands: informationGuideData?.isVisibleToBrands || false,
        module: informationGuideData?.module || null,
        fileType: informationGuideData?.contentFileType || "Document",
        contentFilePath: informationGuideData?.contentFile?.path || "",
        contentFilePublicurl: informationGuideData?.contentFile?.publicurl || "",
        status: informationGuideData?.status || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Name"
                fullWidth
                name="informationGuideName"
                error={
                  formik.touched.informationGuideName &&
                  Boolean(formik.errors.informationGuideName)
                }
                helperText={
                  formik.touched.informationGuideName &&
                  formik.errors.informationGuideName
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Description"
                fullWidth
                name="informationGuideDescription"
                error={
                  formik.touched.informationGuideDescription &&
                  Boolean(formik.errors.informationGuideDescription)
                }
                helperText={
                  formik.touched.informationGuideDescription &&
                  formik.errors.informationGuideDescription
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                    mr: 1,
                  }}
                  component="legend"
                >
                  Visible to Brands
                </Typography>
                <Switch
                  checked={formik.values.isVisibleToBrands}
                  onChange={(event) =>
                    formik.setFieldValue(
                      "isVisibleToBrands",
                      event.target.checked
                    )
                  }
                  name="isVisibleToBrands"
                  color="primary"
                />
              </Grid>
              {formik.touched?.isVisibleToBrands &&
                formik.errors?.isVisibleToBrands && (
                  <Typography fontSize={12} color="error">
                    {formik.errors?.isVisibleToBrands}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="module" sx={{ fontFamily: "Montserrat" }}>
                  Select Module
                </InputLabel>
                <Field
                  as={Select}
                  value={formik.values.module}
                  label="Select Module"
                  name="module"
                  id="module"
                  onChange={formik.handleChange}
                  fullWidth
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{
                    height: "40px",
                    fontFamily: "Montserrat",
                  }}
                  error={formik.touched.module && !!formik.errors.module}
                  helpertext={formik.touched.module ? formik.errors.module : ""}
                >
                  <MenuItem
                    value="Product Onboarding"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    Product Onboarding
                  </MenuItem>
                  <MenuItem
                    value="Vendor Onboarding"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    Vendor Onboarding
                  </MenuItem>
                  <MenuItem
                    value="Promotion Request"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    Promotion Request
                  </MenuItem>
                  <MenuItem
                    value="Price Update"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    Broadway Documents
                  </MenuItem>
                  <MenuItem value="Reports" sx={{ fontFamily: "Montserrat" }}>
                    Reports
                  </MenuItem>
                </Field>
                {formik.touched?.module && formik.errors?.module && (
                  <Typography fontSize={12} color="error">
                    {formik.errors?.module}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="fileType" sx={{ fontFamily: "Montserrat" }}>
                  File Type
                </InputLabel>
                <Field
                  as={Select}
                  value={formik.values.fileType}
                  label="File Type"
                  name="fileType"
                  id="fileType"
                  onChange={formik.handleChange}
                  fullWidth
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{
                    height: "40px",
                    fontFamily: "Montserrat",
                  }}
                  error={formik.touched.fileType && !!formik.errors.fileType}
                  helpertext={
                    formik.touched.fileType ? formik.errors.fileType : ""
                  }
                >
                  <MenuItem value="Document" sx={{ fontFamily: "Montserrat" }}>
                    Document
                  </MenuItem>
                  <MenuItem value="Video" sx={{ fontFamily: "Montserrat" }}>
                    Video
                  </MenuItem>
                </Field>
                {formik.touched?.fileType && formik.errors?.fileType && (
                  <Typography fontSize={12} color="error">
                    {formik.errors?.fileType}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#000",
                    fontFamily: "Montserrat",
                    fontSize: "12px",
                  }}
                >
                  Upload {formik.values.fileType}
                </span>
                <span
                  style={{
                    color: "#9E9E9E",
                    fontSize: "10px",
                    fontFamily: "Montserrat",
                  }}
                >
                  {" "}
                  {`Please upload ${
                    formik.values.fileType == "Video" ? "MP4" : "PDF, Doc, DocX, Xls or XLSX"} files only.`}
                </span>
              </Typography>
              <FileUploader
                isMultiple={false}
                type={formik.values.fileType}
                fieldName={"Upload Content File"}
                preLoadedFile={
                  !!formik.values.contentFilePublicurl
                    ? [
                        {
                          fileName: formik.values.contentFilePublicurl,
                          displayName: formik.values.contentFilePath,
                        },
                      ]
                    : null
                }
                maxLimit={formik.values.fileType === "Video" ? 500 : 20}
                hasDisplayName
                signedUrlCallback={(newUrl, index, publicUrl) => {
                  formik.setFieldValue("contentFilePath", newUrl);
                  formik.setFieldValue("contentFilePublicurl", publicUrl);
                }}
                isFieldValid={
                  formik.touched?.contentFilePublicurl &&
                  formik.errors?.contentFilePublicurl
                }
              />
              {formik.errors?.contentFilePublicurl && (
                  <Typography fontSize={12} color="error">
                    {formik.errors?.contentFilePublicurl}
                  </Typography>
                )}
            </Grid>
            {informationGuideData && isAdmin && <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="status" sx={{ fontFamily: "Montserrat" }}>
                  Status
                </InputLabel>
                <Field
                  as={Select}
                  value={formik.values.status}
                  label="Status"
                  name="status"
                  id="status"
                  onChange={formik.handleChange}
                  fullWidth
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  sx={{
                    height: "40px",
                    fontFamily: "Montserrat",
                  }}
                  error={formik.touched.status && !!formik.errors.status}
                  helpertext={
                    formik.touched.status ? formik.errors.status : ""
                  }
                >
                  <MenuItem value="Published" sx={{ fontFamily: "Montserrat" }}>
                    Published
                  </MenuItem>
                  <MenuItem value="Inactive" sx={{ fontFamily: "Montserrat" }}>
                    Inactive
                  </MenuItem>
                </Field>
                {formik.touched?.status && formik.errors?.status && (
                  <Typography fontSize={12} color="error">
                    {formik.errors?.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>}
            {/* <p>{JSON.stringify(formik.errors)}</p> */}
            <Grid container spacing={1}>
              <Grid
                container
                xs={12}
                sx={{ mt: 4, ml: 2, pb: 2 }}
                gap={3}
                direction={"row-reverse"}
              >
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton
                  variant="outlined"
                  circular={true}
                  color="dark"
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default InformationGuideForm;
