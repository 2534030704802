import React, { useState, useEffect, useContext } from "react";

// Formik & Yup React Form Validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// @mui material components
import {
  Grid,
  Box,
  Icon,
  IconButton,
  Drawer,
  TextField,
  InputAdornment,
} from "@mui/material";

// Images & Icon
import { Star, Visibility, VisibilityOff } from "@mui/icons-material";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";

// Custom Context
import { useNotification } from "context";

// Apollo Client
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";

// Graphql query & mutation
import { CREATE_BRAND, UPDATE_BRAND, CREATE_CONTACT_DETAILS, UPDATE_CONTACT_DETAILS } from "services/brand-service";
import { GET_BRAND_LIST } from "services/theme-service";
import Loader from "components/MDLoader";
import RequiredLabel from "layouts/stores/requiredLabel";

// Yup Form Validation Schema
const ValidationSchema = Yup.object({
  name: Yup.string()
    .required("Company name is required")
    .max(50, "Maximum 50 characters allowed"),
  authorizedSignatoryName: Yup.string()
    .required("Authorized signatory name is required")
    .max(50, "Maximum 50 characters allowed"),
  username: Yup.string()
    .required("Email Id is required")
    .email("Please enter valid email address"),
  password: Yup.string().required("Password is required"),
  termSheetId: Yup.string()
    .max(50, "Maximum 50 characters allowed"),
  bwSpocName: Yup.string()
    .required("SPOC Name is required")
    .matches(/^[A-Za-z\s]*$/, "Only alphabets and spaces are allowed")
    .max(50, "Maximum 50 characters allowed"),
  bwSpocEmail: Yup.string()
    .required("SPOC Email Id is required")
    .email("Please enter valid email address"),
  bwsSpocMobile: Yup.string()
    .required("SPOC Mobile is required")
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid"),
});

// Yup Form Validation Schema
const ValidationSchemaForUpdate = Yup.object({
  name: Yup.string()
    .required("Company name is required")
    .max(50, "Maximum 50 characters allowed"),
  authorizedSignatoryName: Yup.string()
    .required("Authorized signatory name is required")
    .max(50, "Maximum 50 characters allowed"),
  username: Yup.string()
    .required("Email Id is required")
    .email("Please enter valid email address"),
  password: Yup.string().required("Password is required"),
  termSheetId: Yup.string()
    .max(50, "Maximum 50 characters allowed"),
  bwSpocName: Yup.string()
    .required("SPOC Name is required")
    .matches(/^[A-Za-z\s]*$/, "Only alphabets and spaces are allowed")
    .max(50, "Maximum 50 characters allowed"),
  bwSpocEmail: Yup.string()
    .required("SPOC Email Id is required")
    .email("Please enter valid email address"),
  bwsSpocMobile: Yup.string()
    .required("SPOC Mobile is required")
    .matches(/^[6-9][0-9]{9}$/, "Mobile number is not valid"),
  vendorId: Yup.string().required("ERP Vendor ID is required").matches(/^[a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
  customerId: Yup.string().required("ERP Customer Id is required").matches(/^[a-zA-Z0-9]+$/, 'Only alphabets and numbers are allowed'),
});

const useBrandMutation = (
    brandData,
    handleClose,
    setNotification,
    setKey
) => {
    const mutationOptions = {
        client: dashboardServiceClient,
    };

    const [createCompanyMutation] = useMutation(CREATE_BRAND, mutationOptions);
    const [updateCompanyMutation] = useMutation(UPDATE_BRAND, mutationOptions);

    const [createContactDetailMutation] = useMutation(
        CREATE_CONTACT_DETAILS,
        mutationOptions
    );
    const [updateContactDetailMutation] = useMutation(
        UPDATE_CONTACT_DETAILS,
        mutationOptions
    );

    const handleSubmit = (values) => {
        const payload = {
            name: values?.name,
            username: values?.username,
            SPOCName: values?.bwSpocName,
            SPOCEmail: values?.bwSpocEmail,
            SPOCMobile: values?.bwsSpocMobile,
        };
            
        const mutation = brandData && brandData?.id
                ? updateCompanyMutation
                : createCompanyMutation;
        let key_id = brandData?.brandMetaData?.find(
        (d) => d.key === "ContactDetails"
        )?.id;

        let value = brandData?.brandMetaData?.find(
        (d) => d.key === "ContactDetails"
        )?.value;

        const mutationInput =
        brandData && brandData?.id
            ? {
                input: {
                id: brandData?.id,
                ...payload,
                vendorId: values?.vendorId,
                customerId: values?.customerId,
                },
            }
            : { input: { ...payload, password: values?.password } };

        mutation({ variables: mutationInput })
        .then((response) => {
            if(response.data === null) {
                throw new Error("Brand not created");
            } else {
                    // ? need to update BW spoc details in metadata contact details
                    if (key_id) {
                        return updateContactDetailMutation({
                            variables: {
                                metaDataInput: {
                                    id: key_id,
                                    key: "ContactDetails",
                                    value: {
                                        ...value,
                                        bwSpocName: values?.bwSpocName,
                                        bwSpocEmail: values?.bwSpocEmail,
                                        bwsSpocMobile: values?.bwsSpocMobile,
                                        authorizedSignatoryName: values?.authorizedSignatoryName,
                                    },
                                    brandId: brandData?.id,
                                },
                            },
                            onCompleted: (_data) => {
                                handleClose();
                                setNotification({
                                    color: "success",
                                    isVisible: true,
                                    message: "Company Details Updated Successfully",
                                });
                            },
                            onError: (error) => {
                                console.error("Error:", error?.message);
                                handleClose();
                                setNotification({
                                    color: "error",
                                    isVisible: true,
                                    message: error?.message,
                                });
                            },
                        });
                    } else {
                        return createContactDetailMutation({
                            variables: {
                                metaDataInput: {
                                    key: "ContactDetails",
                                    value: {
                                        bwSpocName: values?.bwSpocName,
                                        bwSpocEmail: values?.bwSpocEmail,
                                        bwsSpocMobile: values?.bwsSpocMobile,
                                        authorizedSignatoryName: values?.authorizedSignatoryName
                                    },
                                    brandId: response.data?.[brandData?.id ? 'updateBrand' : 'createBrand'].id,
                                },
                            },
                            onCompleted: (_data) => {
                                handleClose();
                                setNotification({
                                    color: "success",
                                    isVisible: true,
                                    message: "New Company Created Successfully",
                                });
                            },
                            onError: (error) => {
                                console.error("Error:", error?.message);
                                handleClose();
                                setNotification({
                                    color: "error",
                                    isVisible: true,
                                    message: error?.message,
                                });
                            },
                        });
                }
            }
        })
        .catch((error) => {
            console.error("Error:", error?.message);
            handleClose();
            setNotification({
                color: "error",
                isVisible: true,
                message: error?.message,
            });
        })
        .finally(() => {
            handleClose();
            setKey(new Date())
            // window.location.reload() // TODO: refetch contact details for brandList in brandListingTab
        });
    };
    return handleSubmit;
};

const BrandForm = ({ brandData, handleClose,setKey  }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => event.preventDefault();

    const { setNotification } = useNotification();

    const handleSubmit = useBrandMutation(
        brandData,
        handleClose,
        setNotification,
        setKey
    );

    const contactDetailsData =
        brandData?.brandMetaData?.find((data) => data.key === "ContactDetails") ||
        {};

    return (
        <Formik
        initialValues={{
            name: brandData?.id ? brandData?.name : "",
            authorizedSignatoryName: brandData?.id ? contactDetailsData?.value?.authorizedSignatoryName : "",
            username: brandData?.id ? brandData?.username : "",
            password: brandData?.id ? brandData?.password || "********" : "",
            termSheetId: brandData?.termSheetId || "",
            bwSpocName: brandData?.id ? contactDetailsData?.value?.bwSpocName : "",
            bwSpocEmail: brandData?.id ? contactDetailsData?.value?.bwSpocEmail : "",
            bwsSpocMobile: brandData?.id ? contactDetailsData?.value?.bwsSpocMobile : "",
            vendorId: brandData?.id ? brandData?.vendorId : "",
            customerId: brandData?.id ? brandData?.customerId : "",
        }}
        validationSchema={brandData?.id ? ValidationSchemaForUpdate : ValidationSchema}
        onSubmit={(values) => {
            handleSubmit(values);
        }}
        >
        {(formik) => {
            if(formik.isSubmitting) return <Loader />
            return (
            <Drawer
                anchor="right"
                open={true}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%" } }}
            >
                <Form onSubmit={formik.handleSubmit}>
                <Grid container sx={{ pb: 3, pt: 2, pl: "5px" }}>
                    <Grid item xs={12}>
                    &nbsp;
                    </Grid>
                    <Grid item>
                    <Icon sx={{ ml: 2 }} component={Star} fontSize="large" />
                    </Grid>
                    <Grid item>
                    <p
                        style={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontWeight: "700",
                        marginLeft: "10px",
                        }}
                    >
                        {brandData?.id
                        ? "Update Company Details"
                        : "Create New Company"}
                    </p>
                    </Grid>
                </Grid>
                <Box sx={{ width: "100%", minHeight: "70vh" }}>
                    <Grid container spacing={3}>
                    {brandData?.id && (
                        <>
                        <Grid item xs={11} mx="auto">
                            <Field
                            name="vendorId"
                            as={TextField}
                            label={<RequiredLabel label="ERP Vendor Id"/>}
                            fullWidth
                            value={formik.values.vendorId}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.vendorId && !!formik.errors.vendorId
                            }
                            />
                            <ErrorMessage
                            name="vendorId"
                            component="div"
                            className="error"
                            />
                        </Grid>

                        <Grid item xs={11} mx="auto">
                            <Field
                            name="customerId"
                            as={TextField}
                            label={<RequiredLabel label="ERP Customer Id"/>}
                            fullWidth
                            value={formik.values.customerId}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.customerId &&
                                !!formik.errors.customerId
                            }
                            />
                            <ErrorMessage
                            name="customerId"
                            component="div"
                            className="error"
                            />
                        </Grid>
                        </>
                    )}
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="name"
                        as={TextField}
                        label={<RequiredLabel label="Legal Company Name"/>}
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && !!formik.errors.name}
                        />
                        <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="authorizedSignatoryName"
                        as={TextField}
                        label={<RequiredLabel label="Authorized Signatory Name"/>}
                        fullWidth
                        value={formik.values.authorizedSignatoryName}
                        onChange={formik.handleChange}
                        error={formik.touched.authorizedSignatoryName && !!formik.errors.authorizedSignatoryName}
                        />
                        <ErrorMessage
                        name="authorizedSignatoryName"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="username"
                        as={TextField}
                        label={<RequiredLabel label="Email Id for login"/>}
                        fullWidth
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.username && !!formik.errors.username
                        }
                        />
                        <ErrorMessage
                        name="username"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    <Grid item xs={11} mx="auto">
                        <Field
                        id="password"
                        as={TextField}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        fullWidth
                        disabled={Boolean(brandData?.id)}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        inputProps={{ style: { fontFamily: "Montserrat" } }}
                        InputLabelProps={{ style: { fontFamily: "Montserrat" } }}
                        error={
                            formik.touched.password && !!formik.errors.password
                        }
                        // helperText={formik.touched.password ? formik.errors.password : ""}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        label={<RequiredLabel label="Password"/>}
                        />
                        <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    {/* <Grid item xs={11} mx="auto">
                        <Field
                        name="termSheetId"
                        as={TextField}
                        label="Term Sheet Id"
                        fullWidth
                        value={formik.values.termSheetId}
                        onChange={formik.handleChange}
                        error={formik.touched.termSheetId && !!formik.errors.termSheetId}
                        />
                        <ErrorMessage
                        name="termSheetId"
                        component="div"
                        className="error"
                        />
                    </Grid> */}
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="bwSpocName"
                        as={TextField}
                        label={<RequiredLabel label="Broadway SPOC Name"/>}
                        fullWidth
                        value={formik.values.bwSpocName}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.bwSpocName && !!formik.errors.bwSpocName
                        }
                        />
                        <ErrorMessage
                        name="bwSpocName"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="bwSpocEmail"
                        as={TextField}
                        label={<RequiredLabel label="Broadway SPOC Email Id"/>}
                        fullWidth
                        value={formik.values.bwSpocEmail}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.bwSpocEmail && !!formik.errors.bwSpocEmail
                        }
                        />
                        <ErrorMessage
                        name="bwSpocEmail"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    <Grid item xs={11} mx="auto">
                        <Field
                        name="bwsSpocMobile"
                        as={TextField}
                        label={<RequiredLabel label="Broadway SPOC Mobile No."/>}
                        fullWidth
                        value={formik.values.bwsSpocMobile}
                        onChange={(e)=>{
                            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 10) {
                                formik.setFieldValue('bwsSpocMobile', e.target.value);
                            }
                        }}
                        error={
                            formik.touched.bwsSpocMobile && !!formik.errors.bwsSpocMobile
                        }
                        />
                        <ErrorMessage
                        name="bwsSpocMobile"
                        component="div"
                        className="error"
                        />
                    </Grid>
                    </Grid>
                    <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={3}
                    mx="auto"
                    sx={{
                        marginTop: "4rem",
                    }}
                    >
                    <MDButton
                        type="submit"
                        variant="contained"
                        style={{
                        backgroundColor: "#000000",
                        color: "#fff",
                        borderRadius: "50px",
                        fontFamily: "Montserrat",
                        }}
                    >
                        {brandData?.id ? "Update" : "Create"}
                    </MDButton>
                    <MDButton
                        variant="outlined"
                        color="dark"
                        circular={true}
                        sx={{ borderRadius: "50px", fontFamily: "Montserrat" }}
                        onClick={handleClose}
                    >
                        Cancel
                    </MDButton>
                    </Grid>
                </Box>
                </Form>
            </Drawer>
            );
        }}
        </Formik>
    );
};

export default BrandForm;
