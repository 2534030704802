import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "ui/Breadcrumbs";
import NotificationItem from "ui/Items/NotificationItem";

import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  InputLabel,
  Grid,
  Chip,
  Popover,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Divider,
} from "@mui/material";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "ui/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import MDButton from "components/MDButton";
import { AuthContext, SearchContext, FilterContext } from "context";
import { BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_BRAND_DETAILS } from "services/brand-service";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function DashboardNavbar({ absolute, light, isMini }) {
  // const {
  //   loading: brandListLoading,
  //   error: brandListError,
  //   data: brandList,
  // } = useQuery(BRANDS_LIST, {
  //   client: dashboardServiceClient,
  //   variables: {
  //     filter: {
  //       isDeleted: false,
  //     },
  //   },
  // });
  

  const { user, logout } = useContext(AuthContext);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  let navigate = useNavigate();

  // const brands = data.brands;
  // const names = data?.brands?.map(brand => brand.name);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const { selectedBrands, setSelectedBrands } = useContext(FilterContext);

  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (event) => {
    if (event.target.value.length == 0) {
      setSearchKeyword("");
      setSearchInput("");
    }
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setSearchKeyword(searchInput);
  };

  useEffect(() => {
    setSearchInput(searchKeyword);
  }, [searchKeyword]);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const theme = useTheme();
  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;

    // Determine the new value for selectedBrands
    let newValue;
    if (typeof value === "string") {
      newValue = value.split(",");
    } else {
      newValue = value;
    }

    // If the newValue is an empty array or an empty string, set selectedBrands to null
    if (newValue.length === 0 || newValue === "") {
      setSelectedBrands(null);
    } else {
      setSelectedBrands(newValue);
    }
  };

  const [anchorPosition, setAnchorPosition] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = React.useState(false);

  const handleLogoutOpen = () => {
    setOpenLogoutDialog(true);
  };

  const handleLogoutClose = () => {
    setOpenLogoutDialog(false);
  };

  const handleProfileClick = (event) => {
    setAnchorPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };

  const handleProfileClose = () => {
    setAnchorPosition(null);
  };

  const handleLogout = () => {
    logout();
  };

  const getChipColor = (chipLabel) => {
    switch (chipLabel) {
      case "Live":
        return "success";
      case "Rejected":
        return "error";
      case "Pending":
        return "warning";
      case "New":
        return "info";
      default:
        return "secondary";
    }
  };

  function formatString(input) {
    // Replace underscores with spaces, capitalize each word, and return the result
    return input
      .replace(/_/g, ' ')  // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase());  // Capitalize the first letter of each word
  }

  const hideButtonUrls = [
    "/news",
    "/events",
    "/customer",
    "/channel",
    "/master-attribute",
    "/master-activity",
  ];
  const shouldHideButton = hideButtonUrls.includes(location.pathname);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          {/* <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          /> */}
          { route.includes("events") && (<MDBox display="flex" pr={1}>
            <form
              onSubmit={handleSearchSubmit}
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <MDInput
                label="Search here"
                sx={{ minWidth: "512px" }}
                fullWidth
                value={searchInput} // Controlled component
                onChange={handleInputChange} // Update local state
              />
              <MDButton
                type="submit"
                variant="outlined"
                circular={true}
                color="dark"
                sx={{ marginLeft: 1 }}
              >
                Search
              </MDButton>
            </form>{" "}
          </MDBox>)}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            {/* <MDBox pr={1}>
              <MDInput label="Search here" fullWidth />
            </MDBox> */}
            {/* {user &&
              user?.role === "admin" &&
              !brandListLoading &&
              !brandListError && (
                <MDBox>
                  {!shouldHideButton && <Grid display={"flex"}>
                    <FormControl
                      fullWidth
                      sx={{ minWidth: "8rem", maxWidth: "40rem" }}
                    >
                      <InputLabel>Select Brands</InputLabel>
                      <Select
                        as={Select}
                        multiple
                        label="Select Brand"
                        name="selectedBrands"
                        style={{ padding: "10px", width: "150px" }}
                        value={selectedBrands || []}
                        onChange={handleBrandChange}
                        displayEmpty
                        IconComponent={() => (
                          <GridExpandMoreIcon fontSize="medium" />
                        )}
                      >
                        <MenuItem disabled value="">
                          <em>Select brands</em>
                        </MenuItem>
                        {brandList &&
                          brandList?.brandlist?.results
                            ?.filter((item) => item.brandStatus === "Live")
                            ?.map((brand) => {
                              return (
                                <MenuItem key={brand?.id} value={brand?.id}>
                                  {brand.name || "Unknown Brand"}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                  </Grid>}
                </MDBox>
              )} */}
            <MDBox
              display="flex"
              alignItems="center"
              color={light ? "white" : "inherit"}
            >
              {/* {brandDetails?.brand?.brandStatus && (
                <Chip
                  sx={{
                    color: "white.main",
                    mr: 1,
                    borderRadius: 2,
                    px: 1,
                    py: 0.5,
                    fontFamily: "Montserrat",
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  label={brandDetails?.brand?.brandStatus}
                  color={getChipColor(brandDetails?.brand?.brandStatus)}
                  size="small"
                />
              )} */}
              <IconButton
                aria-label="profile"
                color="dark"
                size="large"
                onClick={(e) => {
                  handleProfileClick(e);
                }}
              >
                <Icon component={AccountCircleRoundedIcon} />
              </IconButton>
              <Popover
                open={Boolean(anchorPosition)}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
                onClose={handleProfileClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "#fff", // Set background color to white
                  },
                }}
              >
                <Box sx={{ padding: "10px 20px 10px 10px" }}>
                  <Grid>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: 15,
                      }}
                    >
                      User : <b>{user.username}</b>
                    </Typography>
                  </Grid>
                  {/* {user &&
                    user?.role !== "admin" &&
                    !brandListLoading &&
                    !brandListError && (
                      <Grid>
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: 15,
                          }}
                        >
                          Brand : <b>{user.name}</b>
                        </Typography>
                      </Grid>
                    )} */}
                  <Grid>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: 15,
                      }}
                    >
                      Role :{" "}
                      <b>
                        {formatString(user?.role)}
                      </b>
                    </Typography>
                  </Grid>
                </Box>
              </Popover>
              {/* <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link> */}
              <IconButton
                aria-label="profile"
                sx={{ color: "red" }}
                size="large"
                onClick={handleLogoutOpen}
              >
                <Icon component={LogoutIcon} />
              </IconButton>
              <Dialog
                open={openLogoutDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleLogoutClose}
              >
                <Grid sx={{ paddingX: "25px" }}>
                  <DialogTitle
                    style={{
                      color: "black",
                      fontFamily: "Montserrat",
                      textAlign: "center",
                      marginBottom: "15px",
                    }}
                  >
                    {"Logout"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      style={{ color: "black", fontFamily: "Montserrat" }}
                    >
                      Are you sure you want to logout?
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <MDButton
                      variant="outlined"
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        fontFamily: "Montserrat",
                        borderRadius: "50px",
                        border: "1px solid black",
                        width: "50%",
                      }}
                      onClick={handleLogoutClose}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      variant="contained"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        fontFamily: "Montserrat",
                        borderRadius: "50px",
                        width: "50%",
                      }}
                      onClick={handleLogout}
                    >
                      Logout
                    </MDButton>
                  </DialogActions>
                </Grid>
              </Dialog>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton> */}
              {renderMenu()}
              <MDBox>
                {/* <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="button"
                  onClick={handleLogOut}
                >
                  Log Out
                </MDButton> */}
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
