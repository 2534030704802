import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormHelperText,
    Checkbox,
    Autocomplete,
    FormGroup,
    IconButton,
    Icon,
} from "@mui/material";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import FileUploader from "components/MDFileUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import { GET_STORES_LIST } from "services/store-service";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import {
    GET_CATEGORY_PRODUCT_LIST,
    UPDATE_BRAND_CATEGORY_STORE,
    CREATE_BRAND_CATEGORY_STORE,
} from "services/brand-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";
import { CREATE_BRAND_STORE } from "services/brand-service";
import { UPDATE_BRAND_STORE } from "services/brand-service";
import { MAP_BRAND_STORE } from "services/brand-service";
import { useGridContext } from ".";
import RequiredLabel from "layouts/stores/requiredLabel";

const user =
    localStorage.getItem("userDetails") &&
        localStorage.getItem("userDetails") !== "undefined"
        ? JSON.parse(localStorage.getItem("userDetails"))
        : null;
const validationSchema = (data) =>
    Yup.object({
        storeName: Yup.string().required("Store name is required"),
        startDate: Yup.date()
            .required("Start date is required")
            .test(
                "startDate",
                "Start date must be at least one day after today",
                function (value) {
                    return dayjs(value).isAfter(dayjs(), "day");
                }
            ),
        agreementValue: Yup.number()
            .required("Agreement value is required")
            .max(2147483647,"Please enter a valid agreement value"),
        termSheetId: Yup.string().required("Term sheet id is required")
            .max(50, "Maximum 50 characters allowed"),
        endDate: Yup.date()
            .required("End date is required")
            .min(
                Yup.ref("startDate"),
                "End date must be at least one day after the start date"
            ),
    });
const useStoreAgreementMutation = (
    agreementData,
    handleClose,
    setNotification,
    brandData
) => {
    const {externalGridRef} = useGridContext()
    const mutationOptions = {
        client: dashboardServiceClient,
        refetchQueries: [
            {
                query: MAP_BRAND_STORE,
                variables: {
                    listBrandStoreFilter: {
                        take: 20,
                        skip: 0,
                        search: "",
                        filter: { brand: { id: brandData?.id }, isDeleted: false },
                    }
                },
                fetchPolicy: "network-only",
            },
        ],
    };

    const [createBrandStoreMutation] = useMutation(
        CREATE_BRAND_STORE,
        mutationOptions
    );
    const [updateBrandStoreMutation] = useMutation(
        UPDATE_BRAND_STORE,
        mutationOptions
    );

    const handleSubmit = (values,setSubmitting) => {
        const payload = {
            start: values.startDate,
            end: values.endDate,
            brandId: user && ["admin", "broadway-rm"].includes(user?.role) ? brandData?.id : user.userId,
            storeId: values.storeName,
            agreementValue: values.agreementValue,
            termSheetId: values.termSheetId
        };

        const mutation =
            agreementData && agreementData.id
                ? updateBrandStoreMutation
                : createBrandStoreMutation;
        const mutationInput =
            agreementData && agreementData.id
                ? { updateBrandStoreInput: { id: agreementData.id, ...payload } }
                : { createBrandStoreInput: payload };

        mutation({
            variables: mutationInput,
            onCompleted: (res) => {
                if (res.data === null) {
                    handleClose();
                    setSubmitting(false)
                    setNotification({
                        color: "error",
                        isVisible: true,
                        message: res?.errors?.[0]?.message
                    });
                }
                else{
                    handleClose();
                    setSubmitting(false)
                    setNotification({
                        color: "success",
                        isVisible: true,
                        message:
                            agreementData && agreementData?.id
                                ? "Store Agreement updated successfully"
                                : "Store Agreement created successfully",
                    });
                }
                if (externalGridRef) {
                    externalGridRef.current?.refreshServerSide({purge:true});
                }
            },
            onError: (error) => {
                console.error("Error:", error?.message);
                setSubmitting(false)
                setNotification({
                    color: "error",
                    isVisible: true,
                    message: error?.message || "Something went wrong",
                });
            }
        })
    };

    return handleSubmit;
};

const StoreAgreementForm = ({ agreementData, handleClose, brandData }) => {
    const { setNotification } = useNotification();
    const isAdmin = user && ["admin", "broadway-rm"].includes(user?.role) ? true : false;
    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST, {
        client: dashboardServiceClient,
        variables: { listStoreFilter: { take: 100, skip: 0 } },
    });
    const handleSubmit = useStoreAgreementMutation(
        agreementData,
        handleClose,
        setNotification,
        brandData
    );

    if (storeLoading) return <Loader />;
    if (storeError){
        console.warn(storeError);
        return <p>Error: {storeError?.message}</p>
    } 
    return (
        <Formik
            initialValues={{
                id: agreementData?.id || null,
                storeName: agreementData?.store.id || "",
                startDate: agreementData?.start || "",
                endDate: agreementData?.end || "",
                agreementValue: agreementData?.agreementValue || "",
                termSheetId: agreementData?.termSheetId || "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values,{setSubmitting}) => {
                handleSubmit(values,setSubmitting);
            }}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                isValid,
                isSubmitting,
                handleChange
            }) => (
                <Form>
                    <Grid container spacing={2} sx={{ padding: "20px" }}>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                error={touched.storeName && Boolean(errors.storeName)}
                            >
                                <InputLabel id="demo-simple-select-label" >
                                    Store Name <span style={{color:"red"}}>*</span>
                                </InputLabel>
                                <Select
                                    as={Select}
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="storeName"
                                    label= {<RequiredLabel label="Store Name"/>}
                                    IconComponent={() => (
                                        <ArrowDropDownIcon
                                            style={{ marginRight: "18px", marginTop: "10px" }}
                                        />
                                    )}
                                    value={values.storeName}
                                    onChange={(e) => {
                                        setFieldValue("storeName", e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    error={touched.storeName && Boolean(errors.storeName)}
                                    sx={{ height: 40 }}
                                >
                                    {storeList &&
                                        storeList.stores.results
                                            ?.filter((item) => item.status === "Active")
                                            .map((store) => (
                                                <MenuItem key={store.id} value={store.id}>
                                                    {store.name}
                                                </MenuItem>
                                            ))}
                                </Select>
                                {touched.storeName && errors.storeName ? (
                                    <FormHelperText>{errors.storeName}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        sx={{width:'100%'}}
                                        slotProps={
                                            errors.startDate &&  !!touched.startDate
                                                ? {
                                                    textField: {
                                                        error: !!touched.startDate && errors.startDate,
                                                        helperText: errors.startDate,
                                                    },
                                                }
                                                : null
                                        }
                                        label= {<RequiredLabel label="Start Date"/>}
                                        name="startDate"
                                        format="DD/MM/YYYY"
                                        value={values?.startDate ? dayjs(values?.startDate) : null}
                                        minDate={dayjs().add(1, "day")}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                "startDate",
                                                dayjs(newValue).format("YYYY-MM-DD")
                                            )
                                        }
                                        renderInput={(params) => (
                                            <Field
                                                as={TextField}
                                                {...params}
                                                fullWidth
                                                error={
                                                    touched?.startDate &&
                                                    Boolean(errors?.startDate)
                                                }
                                                helperText={touched?.startDate && errors?.startDate}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        sx={{width:'100%'}}
                                        slotProps={
                                            errors.endDate && !!touched.endDate
                                                ? {
                                                    textField: {
                                                        error: !!touched.endDate && errors.endDate,
                                                        helperText: errors.endDate,
                                                    },
                                                }
                                                : null
                                        }
                                        label= {<RequiredLabel label="End Date"/>}
                                        name="endDate"
                                        format="DD/MM/YYYY"
                                        value={values.endDate ? dayjs(values.endDate) : null}
                                        minDate={dayjs(values.startDate)}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                "endDate",
                                                dayjs(newValue).format("YYYY-MM-DD")
                                            )
                                        }
                                        renderInput={(params) => (
                                            <Field
                                                as={TextField}
                                                {...params}
                                                fullWidth
                                                error={touched?.endDate && Boolean(errors?.endDate)}
                                                helperText={touched?.endDate && errors?.endDate}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} mx="auto">
                            <Field
                            name="agreementValue"
                            as={TextField}
                            type="number"
                            label= {<RequiredLabel label="Agreement Value"/>}
                            fullWidth
                            value={values.agreementValue}
                            onChange={handleChange}
                            error={touched.agreementValue && !!errors.agreementValue}
                            helperText={
                                touched.agreementValue
                                  ? errors.agreementValue
                                  : ""
                            }
                            />
                        </Grid>
                        <Grid item xs={12} mx="auto">
                            <Field
                            name="termSheetId"
                            as={TextField}
                            label= {<RequiredLabel label="Term Sheet Id"/>}
                            fullWidth
                            value={values.termSheetId}
                            onChange={handleChange}
                            error={touched.termSheetId && !!errors.termSheetId}
                            helperText={
                                touched.termSheetId
                                  ? errors.termSheetId
                                  : ""
                            }
                            />
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid container xs={12} sx={{ mt: 4, pb: 2 }} gap={2} flexDirection={"row-reverse"}>
                                {isAdmin ? (
                                    <MDButton
                                        type="submit"
                                        variant="contained"
                                        circular={true}
                                        color="dark"
                                        disabled={isSubmitting}
                                    >
                                        Save
                                    </MDButton>
                                ) : (
                                    <></>
                                )}
                                <MDButton variant={"outlined"} circular={true} onClick={handleClose} color="dark">
                                    {isAdmin ? "Cancel" : "Close"}
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default StoreAgreementForm;
