import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { Grid, Tooltip } from "@mui/material";
import videojs from "video.js";


const GET_SIGNED_URL = gql`
  query getSignedUrl($getSignedUrlRequest: GetSignedUrlInput!) {
    getSignedUrl(getSignedUrlRequest: $getSignedUrlRequest) {
      url
      readUrl
    }
  }
`;

export default function FileUploaderContainer({
    preLoadedFile,
    hasDisplayName = false,
    isMultiple,
    maxSize, // in MB
    dimensionHeight,
    dimensionWidth,
    signedUrlCallback,
    fieldName,
    isFieldValid,
    isPdf,
    type,
    keyPrefix = ''
}) {
    const [fileInstances, setFileInstances] = useState([]);

    useEffect(() => {
        const preFileInstances =
            (preLoadedFile &&
                preLoadedFile.map((file, index) =>
                    hasDisplayName
                        ? {
                            id: index,
                            fileName: file.fileName,
                            displayName: file.displayName,
                        }
                        : { id: index, fileName: file }
                )) ||
            [];
        const stateValue = isMultiple
            ? [...preFileInstances, { id: Date.now() }]
            : preFileInstances.length
                ? [...preFileInstances]
                : [{ id: Date.now() }];
        setFileInstances(stateValue);
    }, [preLoadedFile]);

    const addNewInstance = () => {
        isMultiple && setFileInstances([...fileInstances, { id: Date.now() }]);
    };

    const deleteInstance = (idToDelete) => {
        setFileInstances(
            fileInstances.filter((instance) => instance.id !== idToDelete)
        );
    };

    return (
        <div>
            {fileInstances.map((instance, index) => (
                <Grid container key={instance.id}>
                    <Grid item xs={10} sm={12}>
                        <FileUploader
                            id={keyPrefix + "-" + index}
                            instance={instance}
                            addNewInstance={addNewInstance}
                            deleteInstance={deleteInstance}
                            maxSize={maxSize}
                            dimensionHeight={dimensionHeight}
                            dimensionWidth={dimensionWidth}
                            signedUrlCallback={signedUrlCallback}
                            fieldName={fieldName}
                            isPdf={isPdf}
                            type={type}
                            isMultiple={isMultiple}
                            isFieldValid={isFieldValid}
                        />
                    </Grid>
                </Grid>
            ))}
        </div>
    );
}

function FileUploader({
    addNewInstance,
    deleteInstance,
    id,
    instance,
    maxSize,
    dimensionHeight,
    dimensionWidth,
    signedUrlCallback,
    fieldName,
    isMultiple,
    isFieldValid,
    isPdf,
    type,
}) {
    const { setNotification } = useNotification();

    const [fileName, setFileName] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploaded, setIsUploaded] = useState(
        instance.fileName ? true : false
    );
    const [fileData, setFileData] = useState(null);
    const [axiosCallMade, setAxiosCallMade] = useState(false);
    const [readUrl, setReadUrl] = useState(
        instance.fileName ? instance.fileName : null
    );
    const [openPreview, setOpenPreview] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [fileMetadata, setFileMetadata] = useState(null);

    const fileInputId = `${fieldName}-fileInput-${id}`;

    const [getSignedUrl, { data }] = useLazyQuery(GET_SIGNED_URL, {
        client: dashboardServiceClient,
    });

    useEffect(() => {
        if (data?.getSignedUrl?.readUrl) {
            setPreviewFile(data?.getSignedUrl?.readUrl);
        }
    }, [data?.getSignedUrl?.readUrl]);

    useEffect(() => {
        if (data?.getSignedUrl?.url && fileName && !axiosCallMade) {
            const uploadFile = async () => {
                await axios.put(data?.getSignedUrl?.url, fileData, {
                    headers: { "Content-Type": "application/octet-stream" },
                });
                setUploadProgress(66);
                setAxiosCallMade(true);
                getSignedUrl({
                    variables: {
                        getSignedUrlRequest: {
                            fileName: `dashboard/staging/news/${fileName}`,
                            action: "read",
                            contentType: "application/octet-stream",
                        },
                    },
                });
                // addNewInstance();
                setIsUploaded(true);
                setUploadProgress(100);
            };
            uploadFile();
        }
    }, [data?.getSignedUrl?.url, fileName]);

    useEffect(() => {
        if (isUploaded && data?.getSignedUrl?.readUrl && fileName) {
            signedUrlCallback(
                `dashboard/staging/news/${fileName}`,
                id,
                data?.getSignedUrl?.readUrl,
                fileData,
                fileName,
                fileMetadata
            );
            setReadUrl(data?.getSignedUrl?.readUrl);
        }
    }, [isUploaded, data?.getSignedUrl?.readUrl]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        // Check if the file is of a valid format
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setNotification({
                color: "warning",
                isVisible: true,
                message: "Selected file format is not allowed.",
            });
            return false;
        }
    
        // Validate file size
        let maxFileSize = maxSize ? maxSize : 6; // Max size in MB
        if (file.size > maxFileSize * 1024 * 1024) {
            setNotification({
                color: "warning",
                isVisible: true,
                message: `File size exceeds the ${maxFileSize}MB limit.`,
            });
            return false;
        }
    
        // Check the dimensions of the image before proceeding
        const loadImage = (file) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(img); // Resolving the image object when loaded
                img.onerror = reject;
                img.src = URL.createObjectURL(file); // Load the image as a URL
            });
        };
    
        try {
            loadImage(file).then((img) => {
                
                // Commenting the validation for now as per request from Anuj.

                // if (img.width !== dimensionWidth || img.height !== dimensionHeight) {
                //     setNotification({
                //         color: "warning",
                //         isVisible: true,
                //         message: `The image dimensions should be ${dimensionWidth}x${dimensionHeight}.`,
                //     });
                //     return;
                // }
                setFileMetadata({
                    dimension: `${img.width} x ${img.height}`,
                    format: file.type.split('/')[1],
                    size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
                    type: 'image',
                });
    
                // Now, we can set the file data and proceed with uploading
                setFileName(file.name);
                setFileData(file);
                setUploadProgress(40);
    
                getSignedUrl({
                    variables: {
                        getSignedUrlRequest: {
                            fileName: `dashboard/staging/news/${file.name}`,
                            action: "write",
                            contentType: "application/octet-stream",
                        },
                    },
                });
    
                setAxiosCallMade(false); // Reset axios call state
            }).catch((error) => {
                setNotification({
                    color: "warning",
                    isVisible: true,
                    message: "Error loading image: " + error.message,
                });
            });
        } catch (error) {
            console.error('Error loading image:', error);
            setNotification({
                color: "warning",
                isVisible: true,
                message: "Error loading image: " + error.message,
            });
        }
    };
    

    const handleDelete = () => {
        deleteInstance(instance.id);
        signedUrlCallback(null, id);
    };

    const handlePreviewOpen = (file) => {
        if (readUrl === null) return;
        if (!(readUrl && /^https?:\/\//.test(readUrl))) {
            getSignedUrl({
                variables: {
                    getSignedUrlRequest: {
                        fileName: `${readUrl}`,
                        action: "read",
                        contentType: "application/octet-stream",
                    },
                },
            });
        }

        if (file === "") {
            setOpenPreview(true); // Handle image preview (open modal or perform other logic)
        } else if (isUploaded && file.match(/\.(pdf|PDF)$/)) {
            window.open(readUrl); // Open PDF in new tab
        } else {
            setOpenPreview(true); // Handle image preview (open modal or perform other logic)
        }
    };

    const handlePreviewClose = () => {
        setOpenPreview(false);
    };

    const truncateFilename = (name) => {
        if (!name) return '';
        return name.length > 10 ? `${name.slice(0, 10)}...` : name;
    };

    return (
        <>
            <Paper
                elevation={0}
                onClick={() =>
                    !isUploaded && document.getElementById(fileInputId).click()
                }
                sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "8px 0px 8px 16px",
                    backgroundColor: isUploaded ? "#6a994e29" : "#fafafa",
                    maxHeight: "52px",
                    borderRadius: "4px",
                    border: `1px ${isFieldValid ? "solid" : isUploaded ? "solid" : "dashed"
                        } ${isFieldValid ? "#F44335" : isUploaded ? "#6a994e" : "#efefef"}`,
                }}
            >
                <input
                    type="file"
                    id={fileInputId}
                    style={{ display: "none" }}
                    accept={isPdf ? "image/*, application/pdf" : "image/*,video/*"}
                    onChange={handleFileChange}
                />
                {isUploaded ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                height: "52px",
                                gap: "16px",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src="https://generation-sessions.s3.amazonaws.com/7c71c9772decb49fcc575053870ef5ff/img/tick-circle@2x.png"
                                alt="tick"
                                style={{ width: "24px", height: "24px" }}
                            />
                            <div
                                style={{
                                    width: `${window.innerWidth - 4000}px`,
                                    fontFamily: "Inter-Medium, Helvetica",
                                    fontWeight: 500,
                                    color: "#000000",
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                <Tooltip title={fileName ||
                                    (instance?.displayName
                                        ? instance?.displayName?.split("/").reverse()[0]
                                        : instance?.fileName
                                            ? instance?.fileName.split("/").reverse()[0].split("?")[0]
                                            : "")}>
                                    <Typography
                                        style={{
                                            fontFamily: '"Inter-Bold", Helvetica',
                                            fontWeight: 500,
                                            color: "#000000",
                                            fontSize: "14px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        {truncateFilename(fileName ||
                                            (instance?.displayName
                                                ? instance?.displayName?.split("/").reverse()[0]
                                                : instance?.fileName
                                                    ? instance?.fileName.split("/").reverse()[0].split("?")[0]
                                                    : ""))}
                                    </Typography>
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "inline-flex",
                                justifyContent: "flex-end",
                                gap: "8px",
                                padding: "10px 16px",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: '"Inter-Bold", Helvetica',
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    color: "#6a994e",
                                }}
                            >
                                Uploaded
                            </Typography>
                        </div>
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <img
                            src="https://generation-sessions.s3.amazonaws.com/df5a8cb33081b6df29ee38b7b81444bc/img/gallery-export@2x.png"
                            alt="Gallery"
                            style={{ width: "24px", height: "24px" }}
                        />
                        <Typography variant="body2">
                            {fileName ? `Uploading ${fileName}` : fieldName}
                        </Typography>
                    </div>
                )}
                {fileName && !isUploaded && (
                    <div
                        style={{
                            width: `${uploadProgress}%`,
                            backgroundColor: "red",
                            height: "5px",
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                        }}
                    ></div>
                )}
                <div
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "8px",
                        padding: "10px 16px",
                    }}
                >
                    {isUploaded && (
                        <Button
                            sx={{ color: "#344767", pl: 0 }}
                            onClick={() => {
                                handlePreviewOpen(instance?.displayName || "");
                            }}
                        >
                            PREVIEW
                        </Button>
                    )}
                    <Button
                        sx={{ color: "#344767", pl: 0 }}
                        onClick={
                            isUploaded
                                ? isMultiple
                                    ? handleDelete
                                    : () => {
                                        document.getElementById(fileInputId).click();
                                    }
                                : null
                        }
                    >
                        {isUploaded ? (isMultiple ? "DELETE" : "CHANGE") : "CHOOSE FILE"}
                    </Button>
                </div>
            </Paper>

            <Modal
                open={openPreview}
                onClose={handlePreviewClose}
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <img
                    src={previewFile || readUrl}
                    alt="Preview"
                    style={{ maxWidth: "80%", maxHeight: "80%" }}
                />
            </Modal>
        </>
    );
}
